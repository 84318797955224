.common-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.common-footer p {
    margin: 0;
    font-size: 0.6rem !important;
    letter-spacing: 0.075rem;
    text-transform: uppercase;
    line-height: 2rem !important;    
    color: #ebebeb;
}

.common-header {
    display: flex !important;
    letter-spacing: 0.075rem;
    color: #007bff;
    background-color: #27293d;
    padding: 0.5rem 0.25rem 0.25rem 0.25rem;
}

#header-left {
    margin-left: 1.5rem;  
    text-shadow: 2px 2px 2px black;  
}

#header-right {
    margin-right: 1.5rem;
    text-shadow: 2px 2px 2px black;
    display: none !important;
}

#header-center {
    text-transform: uppercase;
    text-shadow: 2px 2px 2px black;
}

#company-brand-text {
    font-size: 0.95rem;
    padding-left: 0.35rem;
    color: #c6c6c6;
    text-transform: uppercase;
}

/*** Responsive Breakpoint styles ***/

/* Potrait Phones (xs) */
@media (max-width: 575.98px) {
    
}
    
/* Landscape Phones (sm) */
@media (min-width: 576px) {

    .common-footer {
        font-size: 0.5rem;
    }   
}
    
/* Tablets (md) */
@media (min-width: 768px) {

    #header-right  {
        display: inline !important;
      }
}
