.landing-content h1 {
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: 700;   
    color: white;
    text-transform: capitalize;
    letter-spacing: 0.1rem;
}

.landing-content hr.divider {
    max-width: 20%;
    border-width: 0.4rem;
    border-radius: 0.25rem;
    border-color: #007bff;
}

.userRegistration-form-container form {
    max-width: 480px;
    animation: slideInFromLeft 1s ease-in;
}

.userRegistration-form-container .site-button {
    border-bottom-right-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; 
}


/* ANIMATIONS */

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0)
    }
}



