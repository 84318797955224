.scenario-container p,
.scenario-container li,
.scenario-container h2,
.scenario-container h5,
.scenario-container h6 {
    color:#fff !important;
    word-spacing: 0.15rem !important;
}

.scenario-container p,
.scenario-container #list-text {
    font-size: 0.9rem !important;
}

.scenario-container .card h5,
.scenario-container .card h6 {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.scenario-container .card h5::before,
.scenario-container .card h6::before {
    content: '';
    position: absolute;
    width: 40%;
    height: 8px;
    z-index: -1;
    bottom: -.05rem;
    left: -1rem;
    background: rgba(0, 123, 255, 0.5);
    border-radius: 1rem;
}

.scenario-container #wrong-answer::before {
    background: rgba(223,41,45,0.5) !important
}

.scenario-container blockquote {
    position: relative;
    z-index: 1;
    padding: 2rem;
    color: #fff !important;
    font-size: 0.9rem !important;
}

.scenario-container blockquote::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    font-family: 'FontAwesome';
    content: '\f10d';
    color: white;
    opacity: 0.15;
    font-size: 2.5rem;
}

.scenario-container blockquote::after {
    position: absolute;
    z-index: -1;
    bottom: 0;
    font-family: 'FontAwesome';
    content: '\f10e';
    color: white;
    opacity: 0.15;
    font-size: 2.5rem;
}

.scenario-container .list-group-item {
    padding: 0.5rem 1.25rem !important;
}

.scenario-container .card {
    height: 100%;
}

.scenario-container .card img {
    opacity: 0.25;
    width: 100%;
}

.scenario-container .practiceItem #practice-text {
    /* color: grey; */
    color: rgb(160, 160, 160);
    font-size: 0.9rem;
}

.scenario-container .practiceItem:hover {
  background-color: #007bff80 !important;
}

.scenario-container .practiceItem:hover #practice-text {
    color: white !important;
  }