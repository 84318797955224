/*** Dashboard Container styles ***/

.dashboard-container {
  display: flex;
  position: relative;
  flex-direction: column;;
  min-height: 100vh;
  background-color: #1e1e2f !important;
  color: white;
}

.dashboard-container .transition-group { position: relative; }

.dashboard-container .transition-container { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: calc(100vh - 56px); /* ensures footer is pushed to the bottom*/
  background-color: #1e1e2f !important;
}

.dashboard-container footer {
  width: 100%;
  text-align: center;
}

/* .settings-container .card-header,
.settings-container .list-group-item-text {
  color: rgb(0,0,0);
} */
.settings-container .list-group-item {
  padding-bottom: 0;
  background-color: transparent;
  color: white;
}

/* .settings-container .list-group-item-text {
  font-size: 0.9rem;
} */

/*** Common Component Container styles ***/

.component-container {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.component-container .table td,
.awareness-container .list-group-item {
  border-top: 1px solid rgb(0, 123, 255) !important; 
   color: #fff;
}

.component-container .table th {
  border: 0;
  color: #fff;
}


/*** Dashboard Navbar styles ***/

.dashboard-container .dropdown-menu {
  background-color: #27293d !important;
  float: left !important;
}

.dashboard-container .navbar {
  padding-left: 1.5rem !important;
}

.dashboard-container .navbar-nav {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Nav items text and icon*/
.dashboard-container .navbar-collapse a,
.dashboard-container .navbar-collapse button {
  color: #fff !important;
  font-size: 0.85rem !important;
  background-color: inherit !important;
}

.dashboard-container .navbar-collapse a:focus,
.dashboard-container .navbar-collapse button:focus,
.dashboard-container .navbar-collapse a:active,
.dashboard-container .navbar-collapse button:active {
    color: rgba(0, 123, 255) !important;
} 

.dashboard-container .navbar-collapse a:hover,
.dashboard-container .navbar-collapse button:hover{
  color: rgba(0, 123, 255, 0.4) !important;
}

#policy-list-item .nav {
  border-bottom: solid 1px rgba(0, 0, 0, 0.125) ;
}

#scenario-list-item .highlightReport::after {
  font-family: 'FontAwesome';
  content: '\f0a5';
  margin-left: 0.75rem;
}


/*** Miscillaneous styles ***/

.home-container {
  /* display: flex;
  flex-wrap: wrap; */
  align-items: center;
  justify-content: space-evenly;
}

.home-container #get-started-card {
  max-width: 320px;
}

.home-container a {
  text-decoration: none !important;
  color: white;
}

.dashboard-cta-card {
  background-color: #27293d !important;
  margin-bottom: 1rem;
}

.dashboard-cta-card .card-body p {
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.dashboard-cta-card .card-footer {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 550;
}

.dashboard-cta-card .card-footer a {
  border: 1.5px solid #007bff;
  border-radius: 3px;
  padding: .5rem 0.75rem;
}

.dashboard-cta-card .card-footer a:hover{
  background: #007bff;
  color: white;
  text-decoration: none;
}

svg.apexcharts-svg { background: transparent !important; }

.columnChart { display: none; }

.form-check-input {
  margin: 0 !important;
  position: inherit !important;
}

#practices-list{
  position: relative;
  padding-left: 2rem;
}

#policy-goal {
  display: flex;
  justify-content: center;
  align-items: center;
}

#policy-goal p {
  margin-bottom: 0 !important;
}

#policy-goal::before {
  font-family: 'FontAwesome';
  content: '\f05a';
  margin-right: 1rem;
  font-size: 3rem;
}

#practices-list::before {
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'FontAwesome';
  content: '\f046';
  color: #007bff;
}


.program-container #flex-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.program-container #flex-item-graph {
  flex-shrink: 0;
  margin-bottom: 1rem;
}

.program-container #scenarioTab-nav {
  flex-shrink: 0;
}

.program-container #scenario-question blockquote {
  position: relative;
  z-index: 1;
  padding: 0 1rem;
}

.program-container #scenario-question blockquote::before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2rem;
  font-family: 'FontAwesome';
  content: '\f10d';
  color: white;
  opacity: 0.2;
  z-index: -1;
}

.program-container #scenario-question blockquote::after {
  position: absolute;
  bottom: 0;
  right: auto;
  font-size: 2rem;
  font-family: 'FontAwesome';
  content: '\f10e';
  color: white;
  opacity: 0.2;
  z-index: -1;
}

.program-container #scenarioTab-nav .nav-item {
  font-family: Lato;
  font-size: 0.9rem;
  word-spacing: 0.15rem;
  padding-bottom: 0.75rem !important;
  margin-right: 2rem;
}

.program-container #scenarioTab-nav .nav-item i {
  color: rgb(0, 123, 255); ;
}

#list-number {
  font-size: 3rem;
  opacity: .15;
}

#list-text {
  text-align: left !important;
  position: relative;
  left: -0.25rem;
  transition: 0.75s;
}

.policyItem:hover,
#settings-list .list-group-item:hover {
  background-color: #007bff80 !important;
  color: white;
}

#settings-list .list-group-item:focus {
  background-color: initial;
  color: white;
}

.policyItem:hover #list-text {
  left: 1rem;
  transition: 0.5s;
}

.policyItem:hover #list-number {
  opacity: 0.75;
  transition: 0.5s;
}

/* Tablets (md) */
@media (min-width: 576px) {  
  .columnChart { display: initial; }
}

/* Tablets (md) */
@media (min-width: 768px) { 
  #scenario-exercise { margin-top: 0 !important;}
}

/* Desktops (lg) */
@media (min-width: 992px) {
  #scenario-list-item { width: 100%; }
  #policy-list-item { width: 100%; }
}

