.start {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
}

.start .startLinks {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
}

.start .startLink,
.contact-form .form-text {
    font-size: 0.875rem;
    color: #555 !important;
}

.start .card-container {
    margin-top: 60px;
}

.start .card-container .card {
    opacity: 0.7;
    max-width: 720px;
    z-index: 3;
}

.start .container .card p {
    font-size: 0.9rem !important;
}

.start .form-container {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    z-index: 3;
}

.start .form {
    background-color: rgba(255, 255, 255, 0.6);
    padding: 0.5rem 2rem;
    width: 25rem;
    max-width: 90%;
    max-height: 100%;
    border-radius: 1rem;
    overflow: hidden;
    z-index: 10;
}

.start .contact-form {
    width: 40rem !important;
    max-height: 80%;
}

.start .form .form-button {
    border-radius: 1.5rem;
    border: 1px solid steelblue;
    background-color: steelblue;
    padding: .5rem 3rem;
}

/* .start .form-container p {
    color: #fff;
    font-size: .75rem;
    padding: .65rem;
    margin-bottom: 0;
} */

.start .form-container h3 {
    font-size: 1.25rem;
}

.start .alert {
    padding-top: .2rem;
    padding-bottom: .2rem;
    max-width: 90%;
}