* {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar .nav-link {
  color: #fff !important;
  font-size: 0.85rem;
}

.navbar-brand img {
  height: 1.5rem;
  width: auto;
}

#navIcon {
  padding-right: 0.35rem;
}


/* COLORS */

.light {
  color: rgb(160, 160, 160) !important;
}

.bg-container-navy {
  background-color: #27293d !important;
}

.bg-opaque-dark {
  background: rgba(0, 0, 0, 0.8) !important;
}


/* TEXT FORMATTING */

p {
  font-size: 1rem;
}

h5,
h6 {
  font-weight: 600 !important;
  letter-spacing: 0.075rem !important;
}

.content-text {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
}

.text-center {
  text-align: center !important;
}

.errorText {
  font-size: 0.875rem;
  color: red;  
}

.smallText {
  font-size: 0.75rem !important;
}

.card-title {
  letter-spacing: 0.075rem;
} 

.section-header, 
.section-header th {
  font-size: 1rem !important;
  color:  white;
  font-weight: 400 !important; 
  letter-spacing: 0.075rem;
  vertical-align: top !important;
}

.section-header {
  text-align: center;
}

/* Layouts */
.top-container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.top-container-dark {
  position: relative;
  min-height: 100vh;
  background-color: #1e1e2f;
}

.bg-img-overlay {    
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.content-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* Animations */

.inner {
  overflow: hidden;
}

.inner img {
  transition: all 1.5s ease !important;
}

.inner:hover img { 
  transform: scale(1.25) !important;
}

.fade-appear,
.fade-enter {
  opacity: 0.01;
}

.fade-appear.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}


/* Potrait Phones (xs) */
@media (max-width: 575.98px) {

  .hide-on-small-screen {
      display: none !important;
  }

  p, h1, h2, h3, h4, h5, h6 {
    letter-spacing: normal;
  }

  h1 {
    font-size: 1.5rem !important;
  }

  h2 {
    font-size: 1.25rem !important;
  }

  h3 {
    font-size: 1.2rem !important;
  }

  h4 {
    font-size: 1.1rem !important;
  }

  h5 {
    font-size: 0.9rem !important;
  }

  /* h6 {
    font-size: 0.75rem !important;
  } */
}
